import React from 'react'

import Button from '../Button/Button'
import { setGlobalState } from '../../utils/globalState'

import { section, sectionTitle } from './PreFooter.module.scss'

const PreFooter = ({ ...props }) => {
  const openModal = () => {
    setGlobalState('showGetStart', true)
  }

  return (
    <div className={section} {...props}>
      <div className={sectionTitle}>Discuss your project</div>
      <Button secondary onClick={openModal}>
        Get started
      </Button>
    </div>
  )
}

export default PreFooter
