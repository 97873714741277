import React from 'react'
import classnames from 'classnames'

import ParallaxBackground from '../ParallaxBackground/ParallaxBackground'
import RichText from '../RichText/RichText'

import { section, container, image } from './AboutUs.module.scss'

const AboutUs = ({ photo, text, ...props }) => {
  return (
    <div className={section}>
      <div className={classnames('container small', container)} {...props}>
        <div className={image}>
          <img
            src={photo.sourceUrl}
            srcSet={photo.srcSet}
            alt={photo.altText}
            width={photo.mediaDetails.width}
            height={photo.mediaDetails.height}
          />
        </div>
        <RichText>{text}</RichText>
      </div>
      <ParallaxBackground />
    </div>
  )
}

export default AboutUs
