import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import Seo from '../components/Seo/Seo'
import Hero from '../components/Hero/Hero'

import AboutUs from '../components/AboutUs/AboutUs'
import PreFooter from '../components/PreFooter/PreFooter'

const DelayDisruptionAnalysis = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        page(id: "33", idType: DATABASE_ID) {
          title
          featuredImage {
            node {
              sourceUrl
              srcSet
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          aboutUs {
            photo {
              sourceUrl(size: MEDIUM)
              srcSet(size: MEDIUM)
              altText
              mediaDetails {
                width
                height
              }
            }
            text
          }
          seo {
            title
          }
        }
      }
    }
  `)

  const { title, seo, featuredImage } = data.wp.page

  return (
    <>
      <Seo wpSeo={seo} />
      <Hero title={title} featuredImage={featuredImage.node} />
      <AboutUs {...data.wp.page.aboutUs} />
      <PreFooter />
    </>
  )
}

export default DelayDisruptionAnalysis
